import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";

import {
  createArticle,
  updateArticle,
} from "../../../redux/article/article.actions";

const AddArticle = ({ article, createArticle, updateArticle, update }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [buttonArticle, setButtonArticle] = useState(null);

  const handleShowDialog = (e) => {
    setShowDialog(true);
  };
  const hideDialog = () => {
    setShowDialog(false);
  };

  const handleUpdate = (e) => {
    let art = article.articles.find((art) => art._id === e.target.id);
    setButtonArticle(art);
    setShowDialog(true);
  };

  const handleUpload = async (id) => {
    setSpinner(true);
    let form = new FormData();
    form.append("pdf", id.files[0]);
    if (buttonArticle !== null)
      await updateArticle({ form, obj: buttonArticle });
    else await createArticle(form);
    setSpinner(false);
    setButtonArticle(null);
    setShowDialog(false);
  };

  return (
    <Fragment>
      <Dialog
        header={
          buttonArticle === null ? "Upload an Article" : "Replace the Article"
        }
        footer=''
        visible={showDialog}
        style={{ width: "30vw" }}
        onHide={hideDialog}
        baseZIndex={1000}
      >
        <Fragment>
          {spinner === true ? <ProgressSpinner /> : <h6>Upload</h6>}
          <FileUpload
            name='file-upload'
            id='file-upload'
            uploadHandler={handleUpload}
            customUpload
            multiple={false}
            auto
            accept='application/pdf'
            maxFileSize={4000000}
            chooseLabel='Load a PDF File'
            emptyTemplate={
              <p className='p-m-0'>
                Drag and drop the PDF file here to upload.
              </p>
            }
          />
        </Fragment>
      </Dialog>
      {update === undefined ? (
        <div className='p-d-flex p-jc-center p-mb-4'>
          <Button onClick={handleShowDialog} label='Upload an Article' />
        </div>
      ) : (
        <div className='p-d-flex p-jc-center'>
          <Button id={update._id} onClick={handleUpdate}>
            Replace {update.numberDisplay}
          </Button>
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  article: state.article,
});

export default connect(mapStateToProps, { createArticle, updateArticle })(
  AddArticle
);
