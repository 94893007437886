import React, { Fragment } from "react";
import { Message } from "primereact/message";

const LoginMessages = ({
  isSubscribedFail,
  emailValid,
  passwordValid,
  isConfirmedEmailFail,
  isAuthFail,
}) => {
  return (
    <Fragment>
      {isSubscribedFail === true && (
        <Message
          severity='error'
          text='You have an account but your subscription is not active'
        />
      )}
      {emailValid === false && (
        <Message severity='error' text='Please use a valid email address' />
      )}
      {passwordValid === false && (
        <Message
          severity='error'
          text='Please make sure that the Password is at least eight characters'
        />
      )}
      {isConfirmedEmailFail === true ? (
        <Message
          severity='error'
          text='The email is not confirmed. An email was just sent for further instructions.'
        />
      ) : isAuthFail ? (
        <Message severity='error' text='Incorrect Email or Password' />
      ) : (
        <div />
      )}
    </Fragment>
  );
};

export default LoginMessages;
