import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import MyProfilePage from "../pages/myProfile-page/myProfile-page.component";

const PrivateRoute = ({
  user: { isLoggedIn, subscriberStatus },
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        !isLoggedIn ? (
          <Redirect to='/' />
        ) : subscriberStatus === "expired" ? (
          <MyProfilePage {...props} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(PrivateRoute);
