import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { Message } from "primereact/message";

import { resetPassword } from "../../redux/user/user.actions";

const ResetPassword = ({ user, history, token, resetPassword }) => {
  const [message, setMessage] = useState("");
  const [passwordIn, setPasswordIn] = useState({
    password: "",
    passwordConfirm: "",
  });
  useEffect(() => {
    if (user.isResetPasswordSent === 1) history.push("/login/changed");
    if (user.isResetPasswordSent === -1) history.push("/login/notchanged");
    // eslint-disable-next-line
  }, [user.isResetPasswordSent]);
  const { password, passwordConfirm } = passwordIn;

  const handleResetPassword = async (e) => {
    if (passwordIn.password !== passwordIn.passwordConfirm) {
      setMessage("Passwords do not match");
      return;
    }
    if (
      passwordIn.password.length < 8 ||
      passwordIn.passwordConfirm.length < 8
    ) {
      setMessage("Password or confirm password is too short");
      return;
    }
    setMessage("");
    await resetPassword({ passwordIn, token });
  };

  const handleInputChange = (e) => {
    setPasswordIn({ ...passwordIn, [e.target.name]: e.target.value });
  };
  return (
    <div className='p-grid container'>
      <div className='p-col-4 p-offset-4 p-shadow-12 p-d-block p-mx-auto'>
        <Card title='Password Reset'>
          <div className='p-field p-fluid'>
            <label htmlFor='password-4'>Password</label>
            <Password
              id='password-4'
              name='password'
              value={password}
              onChange={handleInputChange}
              disabled={false}
            />
          </div>

          <div className='p-field p-fluid'>
            <label htmlFor='password-confirm-4'>Confirm Password</label>
            <Password
              id='passwordConfirm-4'
              name='passwordConfirm'
              value={passwordConfirm}
              onChange={handleInputChange}
              disabled={false}
            />
          </div>

          <div className='p-d-flex p-jc-evenly'>
            <Button
              label='Reset Password'
              disabled={false}
              onClick={handleResetPassword}
            />
          </div>
          <div className='p-d-flex p-jc-evenly'>
            {message.length > 2 && <Message severity='error' text={message} />}
            {user.isResetPassword === -1 && (
              <Message severity='error' text='Password was not updated' />
            )}
          </div>
        </Card>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default withRouter(
  connect(mapStateToProps, { resetPassword })(ResetPassword)
);
