import React, { Fragment } from "react";
import { Image } from "primereact/image";
import TECHNIQUE from '../../assets/images/technique.jpg'

const InnerPage = () => (
  <Fragment>
    <Image
      src={TECHNIQUE}
      alt='Several chess pieces on a board'
      imageStyle={{ width: "100%" }}
    />
    <h1 className='p-text-center'>Technique In Chess</h1>
    <h4>
      To achieve a won game in chess, is hard to do. To win the “won” game is
      even harder. More than one world champion has said that winning the won
      game is the hardest thing to do in chess.
    </h4>

    <h4>
      ChessThinker will teach you technique. What good is being a pawn or piece
      up if you do not have the technique to finish the game? What good is
      knowing an opening so well that after you get a slight advantage, you have
      no clue how to win the game?
    </h4>

    <h4>
      If a player can learn the endgame well in chess then he or she will
      perform well in tournaments. Most players are uncomfortable in the ending.
      It is a horrible feeling to reach a won game in chess and then throw the
      win away because you did not understand the winning technique.
    </h4>

    <h4>
      One of the best ways to learn technique is to give you a position that is
      slightly superior and then make you win the position against strong
      opposition. At ChessThinker.com you will always start with an advantage.
      Your opponent will try to make the win as difficult as possible. It may
      take you many tries before the win is achieved. But when you finally catch
      on to what is needed to win the position, you have increased your
      technique knowledge!
    </h4>

    <h4>
      Chess players learn best from doing, not just listening to or watching
      some coach move pieces around a board. You can tell a person all day long
      how to swim, but until you throw him into the water he will never really
      learn.
    </h4>

    <h4>
      Working on technique can sometimes be frustrating. But by doing this type
      of work and by being on the clock, you will improve greatly if you put the
      time into it.
    </h4>

    <h4>
      For those that have never played in a tournament before, write down your
      moves as you work on these positions just like a tournament. It is better
      to go into tournament battle with knowledge of chess notation, clock
      management, etc. than to experience it first hand at the tournament. For
      example a lot of players lose in tournaments because they are not used to
      score keeping or the constant pressure of a ticking clock.
    </h4>

    <h4>
      At ChessThinker.com there are 12 sets, each containing 100 positions. In
      each position, you will always have an advantage. You MUST win the
      position or you do not get credit. If you are one move from checkmate and
      you allow a stalemate, then it is back to move one! A draw will not get
      you credit either.
    </h4>

    <h4>All great players have good technique. Time to start working yours!</h4>
  </Fragment>
);

const TechniquePage = () => {
  return (
    <Fragment>
      <div className='p-grid container'>
        <div className='p-d-md-none p-col-12'>
          <div className='p-mx-5'>
            <InnerPage />
          </div>
        </div>
        <div className='p-grid container'>
          <div className='p-d-none p-d-md-block p-col-8 p-offset-2'>
            <InnerPage />
          </div>
        </div>
      </div>
    </Fragment>
  );
};


    

export default TechniquePage;
