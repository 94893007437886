import React, { Fragment } from "react";
import {Route, Switch, BrowserRouter as Router} from 'react-router-dom';

import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';

import PrivateRoute from './routing/private-route';
import AdminRoute from "./routing/admin-route";
import HomePage from "./pages/home-page/home-page.component";
import DetailsPage from "./pages/static-pages/details-page.component";
import TechniquePage from "./pages/static-pages/technique-page.component";
import SetsPage from "./pages/static-pages/sets-page.component";
import PricingPage from "./pages/static-pages/pricing-page.component";
import HintsPage from "./pages/static-pages/hints-page.component";
import MembersPage from "./pages/static-pages/members-page.component";
import ContactPage from "./pages/contact-page/contact-page.component";
import LoginPage from "./pages/login-page/login-page.component";
import RegisterPage from "./pages/register-page/register-page.component";
import RegisterFail from "./pages/register-fail/register-fail.component";
import RegisterPass from "./pages/register-pass/register-pass.component";
import AddTimeFail from "./pages/add-time-fail/add-time-fail.component";
import AddTimePass from "./pages/add-time-pass/add-time-pass.component";
import ForgotPasswordPage from "./pages/forgot-password-page/forgot-password-page.component";
import ResetPasswordPage from "./pages/reset-password-page/reset-password-page.component";
import AcceptInvitePage from "./pages/accept-invite-page/accept-invite-page.component";
// import MyHomePage from "./pages/myHome-page/myHome-page.component";
import MyArticlesPage from "./pages/myArticles-page/myArticles-page.component";
import MyContactPage from "./pages/myContact-page/myContact-page.component";
import MyProfilePage from "./pages/myProfile-page/myProfile-page.component";
import MyAdminPage from "./pages/myAdmin-page/myAdmin-page.component";
import ReturnPage from "./pages/return-page/return-page.component";
import Navbar from "./components/navbar/navbar.component";
import "./App.css";

function App() {
  return (
    <Fragment>
      <Router>
        <Navbar />
        <Switch>
          <AdminRoute exact path='/my/admin' component={MyAdminPage} />
          <PrivateRoute exact path='/my/contact' component={MyContactPage} />
          <PrivateRoute exact path='/my/articles' component={MyArticlesPage} />
          <PrivateRoute exact path='/my/profile' component={MyProfilePage} />
          <PrivateRoute exact path='/add-time-pass' component={AddTimePass} />
          <PrivateRoute exact path='/add-time-fail' component={AddTimeFail} />
          <Route exact path='/_my/:page' component={ReturnPage} />
          <Route exact path='/' component={HomePage} />
          <Route exact path='/details' component={DetailsPage} />
          <Route exact path='/technique' component={TechniquePage} />
          <Route exact path='/sets' component={SetsPage} />
          <Route exact path='/pricing' component={PricingPage} />
          <Route exact path='/hints' component={HintsPage} />
          <Route exact path='/members' component={MembersPage} />
          <Route exact path='/contact' component={ContactPage} />
          <Route exact path='/login' component={LoginPage} />
          {/* <Route exact path='/login/changed' component={LoginPage} /> */}
          <Route exact path='/login/:token' component={LoginPage} />
          <Route exact path='/forgot-password' component={ForgotPasswordPage} />
          <Route
            exact
            path='/reset-password/:tok'
            component={ResetPasswordPage}
          />
          <Route
            exact
            path='/accept-invite/:tok'
            component={AcceptInvitePage}
          />
          <Route exact path='/register' component={RegisterPage} />
          <Route exact path='/register/:initialPlan' component={RegisterPage} />
          <Route exact path='/register-pass' component={RegisterPass} />
          <Route exact path='/register-fail' component={RegisterFail} />
        </Switch>
      </Router>
    </Fragment>
  );
}

export default App;
