import React, { Fragment } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import ShowArticles from "./articles/show-articles.component";
import ShowSubscriptions from "./subscriptions/show-subscriptions.component";
import ShowUsers from "./users/show-users.component";
import ShowFens from "./fens/show-fens.component";

const AdminPanel = () => {
  return (
    <Fragment>
      <div className='p-text-center'>
        <h1>Admin Panel</h1>
        <div className='p-grid p-my-6 container'>
          <div className='p-col-12'>
            <TabView>
              <TabPanel header='All Users'>
                <ShowUsers />
              </TabPanel>
              <TabPanel header='Subscriptions'>
                <ShowSubscriptions />
              </TabPanel>
              <TabPanel header='FENS'>
                <ShowFens />
              </TabPanel>
              <TabPanel header='Articles'>
                <ShowArticles />
              </TabPanel>
            </TabView>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AdminPanel;

// OLD In case this is preffered
// return (
//   <Fragment>
//     <div className='p-text-center'>
//       <h1>Admin Panel</h1>
//       <div className='p-grid p-my-6 container'>
//         <div className='p-col-6'>
//           <TabView>
//             <TabPanel header='Subscriptions'>
//               <ShowSubscriptions />
//             </TabPanel>
//             <TabPanel header='Users'>
//               <ShowUsers />
//             </TabPanel>
//           </TabView>
//         </div>
//         <div className='p-col-6'>
//           <TabView>
//             <TabPanel header='Articles'>
//               <ShowArticles />
//             </TabPanel>
//           </TabView>
//         </div>
//       </div>
//     </div>
//   </Fragment>
// );
