import React, { Fragment, useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Menubar } from "primereact/menubar";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
import TOPLOGO from "../../assets/images/top-logo.png";
import { logoutUser } from "../../redux/user/user.actions";

const Navbar = ({ user, history }) => {
  const menu = useRef(null);
  const [navItems, setNavItems] = useState([]);
  useEffect(() => {
    setNavType();
    // eslint-disable-next-line
  }, [user.isLoggedIn]);

  const setNavType = () => {
    if (user.isLoggedIn === true) {
      if (user.role === "admin") return setNavItems(adminPageItems);
      if (user.subscriberStatus === "expired")
        return setNavItems(expiredPageItems);
      return setNavItems(subscriberPageItems);
    } else {
      return setNavItems(guestPageItems);
    }
  };
  const adminHandle = {
    label: "Admin",
    command: async () => {
      history.push("/my/admin");
    },
  };
  const dashboardHandle = {
    label: "Dashboard",
    command: async () => {
      //history.push("/chess");
      window.location.href = "https://app.chessthinker.com/api/v1/chess";
    },
  };

  const profileHandle = {
    label: "Profile",
    command: async () => {
      history.push("/my/profile");
    },
  };

  const articlesHandle = {
    label: "Articles",
    command: async () => {
      history.push("/my/articles");
    },
  };

  const myContactHandle = {
    label: "Question",
    command: async () => {
      history.push("/my/contact");
    },
  };

  const logoutHandle = {
    label: "Logout",
    command: async () => {
      history.push("/");
    },
  };

  const homeHandle = {
    label: "Home",
    command: async () => {
      history.push("/");
    },
  };
  const detailsHandle = {
    label: "Details",
    command: async () => {
      history.push("/details");
    },
  };
  const techniqueHandle = {
    label: "Technique",
    command: async () => {
      history.push("/technique");
    },
  };
  const setsHandle = {
    label: "Sets",
    command: async () => {
      history.push("/sets");
    },
  };

  const pricingHandle = {
    label: "Pricing",
    command: async () => {
      history.push("/pricing");
    },
  };
  const hintsHandle = {
    label: "Hints",
    command: async () => {
      history.push("/hints");
    },
  };

  const membersHandle = {
    label: "Members",
    command: () => {
      history.push("/login");
    },
  };
  const contactHandle = {
    label: "Contact",
    command: () => {
      history.push("/contact");
    },
  };
  const registerHandle = {
    label: "Register",
    command: () => {
      history.push("/register");
    },
  };

  const guestPageItems = [
    homeHandle,
    detailsHandle,
    techniqueHandle,
    setsHandle,
    pricingHandle,
    hintsHandle,
    contactHandle,
    membersHandle,
    registerHandle,
  ];

  const subscriberPageItems = [
    dashboardHandle,
    profileHandle,
    articlesHandle,
    myContactHandle,
    logoutHandle,
  ];
  const adminPageItems = [...subscriberPageItems, adminHandle];

  const expiredPageItems = [profileHandle, logoutHandle];

  return (
    <Fragment>
      <div className='p-d-flex'>
        <img
          className='margin-auto p-my-5 k-wmax-300px'
          src={TOPLOGO}
          alt='Chess Thinker logo'
          height='60px'
        />
      </div>

      <div className='p-d-flex p-jc-end p-d-md-none'>
        <Button
          // icon='pi pi-bars'
          onClick={(event) => menu.current.toggle(event)}
          aria-controls='popup_menu'
          aria-haspopup
          className='navbar p-mr-5'
        >
          <i className='pi pi-bars' style={{ fontSize: "2em" }} />
        </Button>
        <Menu
          model={navItems}
          popup
          ref={menu}
          id='popup_menu'
          className='navbar bg-blue'
        />
      </div>
      <div className='p-d-none p-d-md-block'>
        <Menubar model={navItems} className='navbar' />
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default withRouter(connect(mapStateToProps, { logoutUser })(Navbar));
