import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import { Button } from "primereact/button";

import { forgotPassword } from "../../redux/user/user.actions";
import { validateInput } from "./forgot-password.helpers";

const ForgotPassword = ({ user, forgotPassword }) => {
  const [emailIn, setEmailIn] = useState("");
  const [isValid, setIsValid] = useState(true);

  const handleForgotPassword = (e) => {
    if (!validateInput(emailIn)) return setIsValid(false);
    forgotPassword({ email: emailIn });
  };

  const handleInputChange = (e) => {
    setEmailIn(e.target.value);
    setIsValid(true);
  };
  return (
    <div className='p-grid container'>
      <div className='p-col-6 p-offset-3 p-d-block p-mx-auto'>
        <div className='p-text-center mb-10'>
          <h2>
            Enter your email address to receive your password reset instructions
          </h2>
        </div>
        <div className='p-grid'>
          <div className='p-col-8 p-offset-2 p-fluid p-grid p-dir-col'>
            <div className='p-field p-col-12'>
              <label htmlFor='email-address3'>Email Address</label>
              <InputText
                id='email-address3'
                name='email'
                value={emailIn}
                onChange={handleInputChange}
                disabled={false}
              />
            </div>

            <div className='p-d-flex p-jc-evenly'>
              <Button
                label='Send Reset Instructions'
                onClick={handleForgotPassword}
              />
            </div>
            <div className='p-dflex p-jc-evenly'>
              {user.isForgotPasswordSent === 1 && (
                <Message
                  severity='success'
                  text='Please check your email for a password reset link.'
                />
              )}
              {user.isForgotPasswordSent === -1 && (
                <Message
                  severity='error'
                  text='An email was not sent. Check if your email is correct.'
                />
              )}
              {user.isForgotPasswordSent === -2 && (
                <Message
                  severity='info'
                  text='Your email has not been confirmed yet. A confirmation email was just sent.'
                />
              )}
            </div>
            {isValid === false && (
              <div className='p-d-flex p-jc-evenly'>
                <Message
                  severity='error'
                  text='Please type a valid email address.'
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default withRouter(
  connect(mapStateToProps, { forgotPassword })(ForgotPassword)
);
