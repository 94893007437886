import React, { Fragment } from "react";

const MembersPage = () => {
  
  return (
    <Fragment>
     <h1>Members Page</h1>
    </Fragment>
  );
};

export default MembersPage;
