import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { changeUserName } from "../../redux/user/user.actions";

const MyProfile = ({ user, changeUserName }) => {
  const [userName, setUserName] = useState("");
  const [adding, setAdding] = useState({
    option1: false,
    option2: false,
    option3: false,
  });
  const [nameChanging, setNameChanging] = useState(false);

  const handleInputChange = (e) => {
    setUserName(e.target.value);
  };
  const handleSaveName = async (e) => {
    if (userName.length < 2) return false;
    setNameChanging(true);
    await changeUserName(userName);
    setNameChanging(false);
    setUserName("");
  };

  const handleAdd = async (e) => {
    let planType = "plan1";
    if (e.target.innerText.includes("$10")) planType = "plan1";
    if (e.target.innerText.includes("$27")) planType = "plan2";
    if (e.target.innerText.includes("$99")) planType = "plan3";

    setAdding({
      ...adding,
      option1: planType === "plan1",
      option2: planType === "plan2",
      option3: planType === "plan3",
    });
    try {
      const res = await axios.post(
        "/api/v1/bookings/add-time",
        { planType: planType },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      setAdding({
        option1: false,
        option2: false,
        option3: false,
      });
      window.location.href = res.data.session.url;
    } catch (err) {}
  };

  const ShowSubscriptionExpiration = ({ expires }) => {
    let exp;
    if (expires === "life") exp = "Never";
    else {
      let [year, month, day] = expires.split("-");
      exp = `${month}/${day.split("T")[0]}/${year}`;
    }
    return (
      <Fragment>
        <h6 className='p-text-center'>Subscription Expires on</h6>
        <h4 className='p-text-center'>{exp}</h4>
      </Fragment>
    );
  };

  const ShowDaysLeft = ({ expires }) => {
    let daysLeft;
    if (expires === "life") daysLeft = "Unlimited";
    else {
      daysLeft =
        Math.floor((new Date(expires) - new Date()) / (1000 * 60 * 60 * 24)) +
        1;
    }
    return (
      <Fragment>
        <h6 className='p-text-center'>Days Remaining on the Subscription</h6>
        <h4 className='p-text-center'>{daysLeft}</h4>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <div className='p-grid container'>
        <div className='p-col-8 p-offset-2  p-grid p-dir-col'>
          <div className='p-offset-3 p-fluid p-field p-col-6'>
            <h3 className='p-text-center' htmlFor='profile-name'>
              Welcome {user.userName}
            </h3>
            <InputText
              id='profile-name'
              name='userName'
              placeholder='Change Your User Name'
              value={userName}
              onChange={handleInputChange}
              disabled={user.subscriberStatus === "expired"}
              className='p-mb-4'
            />
            <div className='p-d-flex p-jc-evenly p-mb-5'>
              <Button
                label='Save New Name'
                onClick={handleSaveName}
                loadingIcon='pi pi-spin pi-sun'
                loading={nameChanging}
                disabled={user.subscriberStatus === "expired"}
              />
            </div>
          </div>
          <div className='p-mt-4 p-shadow-12'>
            <Card
              className={user.subscriberStatus === "expired" && "bg-warning"}
            >
              {user.subscriberStatus === "expired" ? (
                <Fragment>
                  <h4 className='p-text-center'>
                    Your subscription is expired
                  </h4>
                  <h4 className='p-text-center p-mb-5'>
                    Please select a button below to extend your subscription
                  </h4>
                </Fragment>
              ) : (
                <Fragment>
                  <h3 className='p-text-center'>Subscription Information</h3>

                  <ShowSubscriptionExpiration
                    expires={
                      user.subscriberStatus === "life"
                        ? "life"
                        : user.expirationDate
                    }
                  />

                  <ShowDaysLeft
                    expires={
                      user.subscriberStatus === "life"
                        ? "life"
                        : user.expirationDate
                    }
                  />
                </Fragment>
              )}

              <div className='p-mt-4 p-d-flex p-jc-evenly'>
                <Button
                  label='Add 1 Month $10'
                  onClick={handleAdd}
                  className='p-button-success'
                  loadingIcon='pi pi-spin pi-sun'
                  loading={adding.option1}
                  disabled={
                    adding.option2 ||
                    adding.option3 ||
                    user.subscriberStatus === "life"
                  }
                />
                <Button
                  label='Add 3 Months $27'
                  onClick={handleAdd}
                  className='p-button-success'
                  loadingIcon='pi pi-spin pi-sun'
                  loading={adding.option2}
                  disabled={
                    adding.option1 ||
                    adding.option3 ||
                    user.subscriberStatus === "life"
                  }
                />
                <Button
                  label='Add 1 Year $99'
                  onClick={handleAdd}
                  className='p-button-success'
                  loadingIcon='pi pi-spin pi-sun'
                  loading={adding.option3}
                  disabled={
                    adding.option1 ||
                    adding.option2 ||
                    user.subscriberStatus === "life"
                  }
                />
              </div>
            </Card>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, { changeUserName })(MyProfile);
