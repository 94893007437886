import React, { Fragment, useState,useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import RegisterPlan from "./register-plan.component";
import RegisterUser from "./register-user.component";
import { validateInputs } from "./register-page.helpers";
import { clearErrors } from "../../redux/user/user.actions";

const RegisterPage = ({
  history,
  user: { isRegistrationSent },
  clearErrors,
  match: {
    params: { initialPlan },
  },
}) => {
  const [userIn, setUserIn] = useState({
    userName: "",
    email: "",
    password: "",
    passwordConfirm: "",
    planType: null,
    emailValid: true,
    passwordValid: true,
    planValid: true,
    nameValid: true,
  });
  const [loading, setLoading] = useState(false);
  const [registerFail, setRegisterFail] = useState("");
  const { userName, email, password, passwordConfirm, planType } = userIn;

  useEffect(() => {
    initialize();
    clearErrors();
    // eslint-disable-next-line
  }, []);

  const initialize = () => {
    if (initialPlan === undefined) return;
    setUserIn({ ...userIn, planType: `plan${initialPlan}` });
  };

  const handleRegister = async (e) => {
    let { emailValid, passwordValid, nameValid, planValid, overallValid } =
      validateInputs(userIn);
    setUserIn({ ...userIn, emailValid, passwordValid, nameValid, planValid });
    if (!overallValid) return;
    let userItem = { userName, email, password, passwordConfirm, planType };
    try {
      setLoading(true);
      const res = await axios.post("/api/v1/bookings/signup", userItem, {
        headers: { "Content-Type": "application/json" },
      });
      setLoading(false);
      if (res.data.bypass !== undefined) setRegisterFail(res.data.bypass);
      else window.location.href = res.data.session.url;
    } catch (err) {}
  };

  const handleInputChange = (e) => {
    setUserIn({ ...userIn, [e.target.name]: e.target.value });
  };

  const goLoginUrl = () => {
    history.push("/login");
  };

  return (
    <Fragment>
      <div className='p-grid p-d-lg-none p-mx-4'>
        <div className='p-col-12'>
          <RegisterPlan userIn={userIn} setUserIn={setUserIn} />
          <RegisterUser
            goLoginUrl={goLoginUrl}
            handleRegister={handleRegister}
            handleInputChange={handleInputChange}
            userIn={userIn}
            isRegistrationSent={isRegistrationSent}
            loading={loading}
            registerFail={registerFail}
          />
        </div>
      </div>

      <div className='p-d-lg-block p-d-none p-grid container'>
        <div className='p-offset-4 p-col-4'>
          <RegisterPlan userIn={userIn} setUserIn={setUserIn} />
          <RegisterUser
            goLoginUrl={goLoginUrl}
            handleRegister={handleRegister}
            handleInputChange={handleInputChange}
            userIn={userIn}
            isRegistrationSent={isRegistrationSent}
            loading={loading}
            registerFail={registerFail}
          />
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default withRouter(
  connect(mapStateToProps, {
    clearErrors,
  })(RegisterPage)
);
