import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { logoutUser } from "../../redux/user/user.actions";
import { Image } from "primereact/image";
import HOMEPAGE from "../../assets/images/home-page.jpg";


const InnerPage = () => (
  <Fragment>
    <Image src={HOMEPAGE} alt='Chess pieces' imageStyle={{ width: "100%" }} />
    <h1 className='p-text-center'>Welcome to ChessThinker!</h1>
    <h4>
      If you are interested in learning how to play chess better, you have come
      to the right place!
    </h4>
    <h4>
      If you have been at the same level or rating for a long period of time I
      must ask, how do you work on your chess? If all you do is play blitz
      online or work on tactics, then something is missing. We have the solution
      at ChessThinker.com
    </h4>
  </Fragment>
);

const HomePage = ({ logoutUser }) => {
  const initialize = async () => {
    await logoutUser();
  };
  useEffect(() => {
    initialize();
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <div className='p-grid p-d-md-none p-mx-2'>
        <div className='p-col-12'>
          <InnerPage />
        </div>
      </div>
      <div className='p-grid container p-d-none p-d-md-block '>
        <div className='p-col-8 p-offset-2'>
          <InnerPage />
        </div>
      </div>
    </Fragment>
  );
};

export default withRouter(connect(null, { logoutUser })(HomePage));
