import React, { Fragment, useState, useEffect } from "react";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import axios from "axios";

const ShowSubscriptions = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(null);

  useEffect(() => {
    getAllSubscriptions();
    // eslint-disable-next-line
  }, []);

  const getAllSubscriptions = async (e) => {
    try {
      const res = await axios.get("/api/v1/bookings", {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
      setSubscriptions(res.data.data);
    } catch (err) {}
  };

  const priceTemplate = (rowData) => `$${rowData.price}.00`;

  const purchaseDateTemplate = (rowData) => {
    let [year, month, day] = rowData.purchaseDate.split("-");
    return `${month}/${day.split("T")[0]}/${year}`;
  };

  const planTemplate = (rowData) => {
    if (rowData.planLength === 0) return "Life";
    if (rowData.planLength === 1) return "1 Month";
    if (rowData.planLength === 3) return "3 Months";
    if (rowData.planLength === 12) return "1 Year";
    return "Not found";
  };

  const header = (
    <div className='p-d-flex p-jc-between'>
      <p className='m-0 fontl'>Subscriptions</p>

      <span className='p-input-icon-left'>
        <i className='pi pi-search' />
        <InputText
          type='search'
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder='Search...'
        />
      </span>
    </div>
  );

  return (
    <Fragment>
      <div className='p-shadow-12'>
        <Card className='small-table'>
          <DataTable
            value={subscriptions}
            dataKey='_id'
            className='p-y-025rem'
            paginator={subscriptions.length > 10}
            paginatorTemplate='CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'
            currentPageReportTemplate='Showing {first} to {last} of {totalRecords}'
            rows={10}
            rowsPerPageOptions={[10, 20, 50]}
            header={header}
            globalFilter={globalFilter}
          >
            <Column
              field='user.email'
              header='Email'
              sortable={true}
              style={{ width: "30%" }}
            />
            <Column
              field='planLength'
              header='Plan Length'
              sortable={true}
              body={planTemplate}
              style={{ width: "20%" }}
            />
            <Column
              field='purchaseDate'
              header='Purchase Date'
              sortable={true}
              body={purchaseDateTemplate}
              style={{ width: "20%" }}
            />
            <Column
              field='price'
              header='Price'
              sortable={true}
              style={{ width: "10%" }}
              body={priceTemplate}
            />
          </DataTable>
        </Card>
      </div>
    </Fragment>
  );
};

export default ShowSubscriptions;
