import axios from "axios";
import { ArticleActionTypes } from "./article.types";

const url = "/api/v1/articles";
const configWithCred = {
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
};
const fileConfig = {
  headers: { "Content-Type": "multipart/form-data" },
  withCredentials: true,
};

export const loadArticles = () => async (dispatch) => {
  try {
    const res = await axios.get(url, configWithCred);

    return dispatch({
      type: ArticleActionTypes.LOAD_ARTICLES_SUCCESS,
      payload: res.data.articles,
    });
  } catch (err) {
    return dispatch({
      type: ArticleActionTypes.LOAD_ARTICLES_FAIL,
    });
  }
};

export const createArticle = (formInfo) => async (dispatch) => {
  try {
    const res = await axios.post(url, formInfo, fileConfig);
    return dispatch({
      type: ArticleActionTypes.CREATE_ARTICLE_SUCCESS,
      payload: res.data.articles,
    });
  } catch (err) {
    return dispatch({
      type: ArticleActionTypes.CREATE_ARTICLE_FAIL,
    });
  }
};
export const updateArticle =
  ({ form, obj }) =>
  async (dispatch) => {
    try {
      const res = await axios.patch(`${url}/${obj._id}`, form, fileConfig);
      return dispatch({
        type: ArticleActionTypes.UPDATE_ARTICLE_SUCCESS,
        payload: res.data.articles,
      });
    } catch (err) {
      return dispatch({
        type: ArticleActionTypes.UPDATE_ARTICLE_FAIL,
      });
    }
  };
