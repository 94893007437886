import React, { Fragment } from "react";
import { Image } from "primereact/image";
import { images } from "./sets-page.images";

const InnerPage = () => (
  <div className='p-grid'>
    {images.map((im) => (
      <Fragment key={im.id}>
        <div className='p-col-6 p-md-4 p-lg-3 p-my-4'>
          <div className='w-25pct'>
            <Image src={im.image} alt={im.alt} imageStyle={{ width: "100%" }} />
          </div>
        </div>
      </Fragment>
    ))}
  </div>
);

const SetsPage = () => {
  return (
    <Fragment>
      <h1 className='p-text-center'>12 Sets</h1>
      <h2 className='p-text-center color-blue'>Each set has 100 positions</h2>
      <div className='p-grid container'>
        <div className='p-d-md-none p-col-12'>
          <div className='p-mx-5'>
            <InnerPage />
          </div>
        </div>
        <div className='p-grid container'>
          <div className='p-d-none p-d-md-block p-col-8 p-offset-2'>
            <InnerPage />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

  

export default SetsPage;
