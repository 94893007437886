import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Image } from "primereact/image";

import PRICING_IMAGE from "../../assets/images/pricing-image.jpg";
import PRICE1 from "../../assets/images/price-1.png";
import PRICE2 from "../../assets/images/price-2.png";
import PRICE3 from "../../assets/images/price-3.png";

const Plan1 = () => (
  <Link className='flex-center' to='/register/1'>
    <img
      src={PRICE1}
      height='200px'
      width='200px'
      alt='Star with 1 month is $10 price'
    />
  </Link>
);
const Plan2 = () => (
  <Link className='flex-center' to='/register/2'>
    <img
      src={PRICE2}
      height='200px'
      width='200px'
      alt='Star with 3 months is $27 price'
    />
  </Link>
);
const Plan3 = () => (
  <Link className='flex-center' to='/register/3'>
    <img
      src={PRICE3}
      height='200px'
      width='200px'
      alt='Star with 12 months is $99 price'
    />
  </Link>
);
const Image1 = () => (
  <div className='p-my-6'>
    <Image
      src={PRICING_IMAGE}
      alt='Chess pieces'
      imageStyle={{ width: "100%" }}
    />
  </div>
);

const PricingPage = () => {
  return (
    <Fragment>
      <div className='p-grid container p-mb-6'>
        <div className='p-col-12 p-d-lg-none'>
          <Image1 />
          <div className='p-my-2  p-grid'>
            <div className='p-col-12'>
              <div className='hw-250px p-m-auto'>
                <Plan1 />
              </div>
            </div>
            <div className='p-col-12'>
              <div className='hw-250px  p-m-auto'>
                <Plan2 />
              </div>
            </div>
            <div className='p-col-12'>
              <div className='hw-250px p-m-auto'>
                <Plan3 />
              </div>
            </div>
          </div>
          <h2 className='p-text-center'>
            Click on the plan you want and begin work on sets
          </h2>
        </div>
        <div className='p-d-none p-d-lg-block p-offset-2 p-col-8'>
          <Image1 />
          <div className='p-my-6  p-d-flex p-jc-between'>
            <div className='hw-250px'>
              <Plan1 />
            </div>
            <div className='hw-250px'>
              <Plan2 />
            </div>
            <div className='hw-250px'>
              <Plan3 />
            </div>
          </div>
          <h2 className='p-text-center'>
            Click on the plan you want and begin work on sets
          </h2>
        </div>
      </div>
    </Fragment>
  );
};

export default PricingPage;
