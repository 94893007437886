import React, { Fragment } from "react";
import AdminPanel from "../../components/admin-panel/admin-panel.component";

const MyAdminPage = () => {
  return (
    <Fragment>
      <AdminPanel />
    </Fragment>
  );
};

export default MyAdminPage;
