import axios from "axios";
import { AdminActionTypes } from "./admin.types";

const configWithCred = {
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
};

export const addNewUser = (userItem) => async (dispatch) => {
  try {
    await axios.post("/api/v1/users/admin-signup", userItem, configWithCred);
    return dispatch({
      type: AdminActionTypes.ADD_NEW_USER_PASS,
    });
  } catch (err) {
    return dispatch({
      type: AdminActionTypes.ADD_NEW_USER_FAIL,
    });
  }
};
export const seederAction = () => async (dispatch) => {
  try {
    await axios.patch("/api/v1/seeders", configWithCred);
    return dispatch({
      type: AdminActionTypes.ADD_NEW_USER_PASS,
    });
  } catch (err) {
    return dispatch({
      type: AdminActionTypes.ADD_NEW_USER_FAIL,
    });
  }
};

export const makeAddNewUserReady = () => async (dispatch) => {
  return dispatch({
    type: AdminActionTypes.ADD_NEW_USER_READY,
  });
};