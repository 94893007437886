import React, { Fragment } from "react";
import { Card } from "primereact/card";

const RegisterFail = () => {
  return (
    <Fragment>
      <div className='p-grid container'>
        <div className='p-offset-3 p-col-6 p-shadow-12 p-d-block p-mx-auto'>
          <Card title='Your Account Registration Did Not Complete'>
            <h4 className='p-text-center'>Please try again or contact us at</h4>
            <h5 className='p-text-center'>administrator@chessthinker.com</h5>
          </Card>
        </div>
      </div>
    </Fragment>
  );
};

export default RegisterFail;
