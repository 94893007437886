export const ArticleActionTypes = {
  LOAD_ARTICLES_SUCCESS: "LOAD_ARTICLES_SUCCESS",
  LOAD_ARTICLES_FAIL: "LOAD_ARTICLES_FAIL",
  CREATE_ARTICLE_SUCCESS: "CREATE_ARTICLE_SUCCESS",
  CREATE_ARTICLE_FAIL: "CREATE_ARTICLE_FAIL",
  UPDATE_ARTICLE_SUCCESS: "UPDATE_ARTICLE_SUCCESS",
  UPDATE_ARTICLE_FAIL: "UPDATE_ARTICLE_FAIL",
  GET_ARTICLE_SUCCESS: "GET_ARTICLE_SUCCESS",
  GET_ARTICLE_FAIL: "GET_ARTICLE_FAIL",
  DELETE_ARTICLE_SUCCESS: "DELATE_ARTICLE_SUCCESS",
  DELETE_ARTICLE_FAIL: "DELETE_ARTICLE_FAIL",
};
