import { ArticleActionTypes } from "./article.types";

const INITIAL_STATE = {
  articles: [],
};

const articleReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ArticleActionTypes.LOAD_ARTICLES_SUCCESS:
      return {
        ...state,
        articles: action.payload,
      };
    case ArticleActionTypes.CREATE_ARTICLE_SUCCESS:
      return {
        ...state,
        articles: action.payload,
      };
    case ArticleActionTypes.UPDATE_ARTICLE_SUCCESS:
      return {
        ...state,
        articles: action.payload,
      };
    case ArticleActionTypes.LOAD_ARTICLES_FAIL:
    case ArticleActionTypes.CREATE_ARTICLE_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default articleReducer;
