import React, { Fragment } from "react";
import MyArticles from "../../components/my-articles/my-articles.component";

const MyArticlesPage = () => {
  return (
    <Fragment>
      <MyArticles />
    </Fragment>
  );
};

export default MyArticlesPage;
