import React, { Fragment } from "react";
import { RadioButton } from "primereact/radiobutton";

const RegisterPlan = ({ setUserIn, userIn }) => {
  return (
    <Fragment>
      <div className='p-my-6'>
        <h1 className='p-text-center'>Select Plan</h1>
        <div>
          <RadioButton
            className='m-12px'
            value='plan1'
            key='plan1'
            name='planType'
            checked={userIn.planType === "plan1"}
            onChange={(e) => {
              setUserIn({ ...userIn, planType: e.value });
            }}
          />
          <label
            htmlFor='plan1'
            className={userIn.planType === "plan1" ? "fontxl-b" : "fontxl-l"}
          >
            One Month for $10.00
          </label>
        </div>
        <div>
          <RadioButton
            className='m-12px'
            value='plan2'
            key='plan2'
            name='planType'
            checked={userIn.planType === "plan2"}
            onChange={(e) => {
              setUserIn({ ...userIn, planType: e.value });
            }}
          />
          <label
            htmlFor='plan2'
            className={userIn.planType === "plan2" ? "fontxl-b" : "fontxl-l"}
          >
            Three Months for $27.00
          </label>
        </div>
        <div>
          <RadioButton
            className='m-12px'
            value='plan3'
            key='plan3'
            name='planType'
            checked={userIn.planType === "plan3"}
            onChange={(e) => {
              setUserIn({ ...userIn, planType: e.value });
            }}
          />
          <label
            htmlFor='plan3'
            className={userIn.planType === "plan3" ? "fontxl-b" : "fontxl-l"}
          >
            One Year for $99.00
          </label>
        </div>
      </div>
    </Fragment>
  );
};

export default RegisterPlan;
