import axios from "axios";
import { UserActionTypes } from "./user.types";

const config = { headers: { "Content-Type": "application/json" } };


export const changeUserName = (newName) => async (dispatch) => {
  try {
    const res = await axios.patch(
      `/api/v1/users/update-my-name`,
      { userName: newName },
      {
        withCredentials: true,
      }
    );

    return dispatch({
      type: UserActionTypes.CHANGE_USERNAME_PASS,
      payload: res.data.data,
    });
  } catch (err) {
    return dispatch({
      type: UserActionTypes.CHANGE_USERNAME_FAIL,
    });
  }
};
export const loadReturnUser = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/v1/users/me", { withCredentials: true });
    const userInfo = {
      userName: res.data.data.data.userName,
      email: res.data.data.data.email,
      role: res.data.data.data.role,
      expirationDate: res.data.data.data.expirationDate,
      subscriberStatus: res.data.data.data.subscriberStatus,
    };
    return dispatch({
      type: UserActionTypes.USER_RETURN_LOADED,
      payload: userInfo,
    });
  } catch (err) {
    return dispatch({
      type: UserActionTypes.AUTH_ERROR,
    });
  }
};

export const loadUser = async (dispatch) => {
  try {
    const res = await axios.get("/api/v1/users/me", { withCredentials: true });
    const userInfo = {
      userName: res.data.data.data.userName,
      email: res.data.data.data.email,
      role: res.data.data.data.role,
      expirationDate: res.data.data.data.expirationDate,
      subscriberStatus: res.data.data.data.subscriberStatus,
    };
    return dispatch({ type: UserActionTypes.USER_LOADED, payload: userInfo });
  } catch (err) {
    return dispatch({
      type: UserActionTypes.AUTH_ERROR,
    });
  }
};

// Login and load user
export const loginUser = (userParams) => async (dispatch) => {
  const output = await logUser(userParams, dispatch);
  if (output.type === "LOGIN_SUCCESS") await loadUser(dispatch);
};
// Just to get the token
const logUser = async (userParams, dispatch) => {
  try {
    let res = await axios.post("/api/v1/users/login", userParams, config);
    if (res.data.bypass === undefined)
      return dispatch({
        type: UserActionTypes.LOGIN_SUCCESS,
      });
    return dispatch({
      type: UserActionTypes.LOGIN_NO_EMAIL,
    });
  } catch (err) {
    return dispatch({
      type: UserActionTypes.LOGIN_FAIL,
    });
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    await axios.get("/api/v1/users/logout", {
      withCredentials: true,
    });

    return dispatch({ type: UserActionTypes.LOGOUT });
  } catch (err) {
    return dispatch({ type: UserActionTypes.LOGOUT });
  }
};

export const clearErrors = () => async (dispatch) => {
  return dispatch({ type: UserActionTypes.CLEAR_ERRORS });
};

export const registerUserComplete = (token) => async (dispatch) => {
  try {
    await axios.get(`/api/v1/users/signup-complete/${token}`, config);
    return dispatch({
      type: UserActionTypes.REGISTRATION_CONFIRM_PASS,
    });
  } catch (err) {
    return dispatch({
      type: UserActionTypes.REGISTRATION_CONFIRM_FAIL,
    });
  }
};

// Password and forget password section
export const forgotPassword = (userParams) => async (dispatch) => {
  try {
    const res = await axios.post(
      "/api/v1/users/forgotPassword",
      userParams,
      config
    );
    let pay = res.data.message.startsWith("Email") ? -2 : 1;

    return dispatch({
      type: UserActionTypes.FORGOT_PASSWORD_PASS,
      payload: pay,
    });
  } catch (err) {
    return dispatch({
      type: UserActionTypes.FORGOT_PASSWORD_FAIL,
    });
  }
};

export const resetPassword = (inputParams) => async (dispatch) => {
  const {
    passwordIn: { password, passwordConfirm },
    token,
  } = inputParams;
  try {
    await axios.patch(
      `/api/v1/users/resetPassword/${token}`,
      { password, passwordConfirm },
      config
    );
    return dispatch({
      type: UserActionTypes.RESET_PASSWORD_PASS,
    });
  } catch (err) {
    return dispatch({
      type: UserActionTypes.RESET_PASSWORD_FAIL,
    });
  }
};

export const setPassword = (inputParams) => async (dispatch) => {
  const {
    passwordIn: { password, passwordConfirm },
    token,
  } = inputParams;
  try {
    await axios.patch(
      `/api/v1/users/verify/${token}`,
      { password, passwordConfirm },
      config
    );
    return dispatch({
      type: UserActionTypes.SET_PASSWORD_PASS,
    });
  } catch (err) {
    return dispatch({
      type: UserActionTypes.SET_PASSWORD_FAIL,
    });
  }
};