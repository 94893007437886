import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { Button } from "primereact/button";
import ArticleColumn from "./article-column.component";
import { loadArticles } from "../../redux/article/article.actions";

const MyArticles = ({ article, loadArticles }) => {
  const [columns, setColumns] = useState({ col1: [], col2: [], col3: [] });
  const [pdfFileUrl, setPdfFileUrl] = useState("");
  useEffect(() => {
    initialize();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    displayArticles();
    // eslint-disable-next-line
  }, [article]);

  const displayArticles = () => {
    if (article.articles.length === 0) return;
    const len = article.articles.length;
    const minCol = Math.floor(len / 3);
    const numMaxCol = len % 3;
    const c1Size = numMaxCol >= 1 ? minCol + 1 : minCol;
    const c2Size = numMaxCol >= 2 ? minCol + 1 : minCol;

    let col1 = article.articles.slice(0, c1Size);
    let col2 = article.articles.slice(c1Size, c1Size + c2Size);
    let col3 = article.articles.slice(c1Size + c2Size);
    setColumns({ col1: col1, col2: col2, col3: col3 });
  };

  const initialize = async () => {
    await loadArticles();
  };

  const handleArticle = async (art) => {
    try {
      let res = await axios(`/api/v1/articles/${art._id}`, {
        method: "GET",
        responseType: "blob",
      });
      let blob = await new Blob([res.data], {
        type: "application/pdf",
      });
      const fileURL = await URL.createObjectURL(blob);

      setPdfFileUrl(fileURL);
    } catch (error) {}
  };

  const ShowArticles = () => {
    return (
      <div className='container p-mt-5'>
        <div className='p-grid'>
          <div className='p-col-4'>
            <ArticleColumn
              column={columns.col1}
              articleHandler={handleArticle}
            />
          </div>
          <div className='p-col-4'>
            <ArticleColumn
              column={columns.col2}
              articleHandler={handleArticle}
            />
          </div>
          <div className='p-col-4'>
            <ArticleColumn
              column={columns.col3}
              articleHandler={handleArticle}
            />
          </div>
        </div>
      </div>
    );
  };

  const ShowPDF = () => {
    return (
      <Fragment>
        <div className='p-d-flex p-jc-center'>
          <Button
            className='p-my-5 bg-blue'
            label='Back to Articles'
            onClick={() => setPdfFileUrl("")}
          />
        </div>
        <div className='p-d-flex p-jc-center'>
          <div className='iframe-container'>
            <iframe
              // src={`${pdfFileUrl}#toolbar=1&thubmnail=0&view=Fit`}
              src={`${pdfFileUrl}#toolbar=1&thubmnail=0&view=Fit`}
              title='resumeFrame'
              width='1000'
              height='1000'
              allowFullScreen=''
            ></iframe>
          </div>
        </div>
      </Fragment>
    );
  };

  return (
    <Fragment>{pdfFileUrl === "" ? <ShowArticles /> : <ShowPDF />}</Fragment>
  );
};

const mapStateToProps = (state) => ({
  article: state.article,
});

export default connect(mapStateToProps, { loadArticles })(MyArticles);
