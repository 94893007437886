import React from "react";
import ResetPassword from "../../components/reset-password/reset-password.component";

const ResetPasswordPage = ({
  match: {
    params: { tok },
  },
}) => {
  return <ResetPassword token={tok} />;
};

export default ResetPasswordPage;
