import React, { useState } from "react";
import { Button } from "primereact/button";
import { Captcha } from "primereact/captcha";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import validator from "validator";
import axios from "axios";

import { FIXED_CONTAINER } from "../../assets/styles/containers.styles";

const Contact = () => {
  const [messageSent, setMessageSent] = useState(false);

  const [inputs, setInputs] = useState({
    mailName: "",
    mailEmail: "",
    mailMessage: "",
  });
  const [isVerified, setIsVerified] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [messageError, setMessageError] = useState("");
  const { mailName, mailEmail, mailMessage } = inputs;

  const handleChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleSendMessage = async () => {
    if (!validator.isEmail(mailEmail)) {
      setEmailError("Enter valid Email!");
      return;
    }
    setEmailError("");
    if (mailMessage.length < 10) {
      setMessageError("Message must be at least 10 characters");
      return;
    }
    setMessageError("");
    if (isVerified === false) return;
    let cont = { mailName, mailEmail, mailMessage };
    await axios.post("/api/v1/messages", cont, {
      headers: { "Content-Type": "application/json" },
    });
    setMessageSent(true);
  };
  const showResponse = () => {
    setIsVerified(true);
  };

  const header = <h1 className='p-text-center'>Contact Us</h1>;
  return (
    <FIXED_CONTAINER>
      <div className='p-fluid p-grid p-dir-col'>
        <div className='p-field pcol-12'>
          <label htmlFor='mail-name'>Full Name (Optional)</label>
          <InputText
            id='mail-name'
            name='mailName'
            value={mailName}
            disabled={false}
            onChange={handleChange}
          />
        </div>
        <div className='p-field pcol-12'>
          <label htmlFor='mail-email'>Email Address</label>
          <InputText
            id='mail-email'
            name='mailEmail'
            value={mailEmail}
            disabled={false}
            onChange={handleChange}
            className={emailError !== "" && "p-invalid"}
          />
          <p className='color-error'>{emailError}</p>
        </div>
        <div className='p-field pcol-12'>
          <label htmlFor='mail-message'>Message</label>
          <InputTextarea
            id='mail-message'
            name='mailMessage'
            value={mailMessage}
            disabled={false}
            rows={10}
            onChange={handleChange}
            className={messageError !== "" && "p-invalid"}
          />
        </div>
        <p className='color-error'>{messageError}</p>
        <div className='p-d-flex p-jc-evenly p-mb-3'>
          <Captcha
            siteKey='6LeWD7gdAAAAAEbcMycFKBTrg_Y5jvi2BpVch5-H'
            onResponse={showResponse}
          />
        </div>
        <div className='p-d-flex p-jc-evenly'>
          <Button
            label='Send Message'
            disabled={messageSent || !isVerified}
            onClick={handleSendMessage}
          />
        </div>
        {messageSent === true && (
          <h4 className='p-text-center'>Your message was sent</h4>
        )}
      </div>
    </FIXED_CONTAINER>
  );
};

export default Contact;
