import React, { Fragment } from "react";
import MyProfile from "../../components/my-profile/my-profile.component";

const MyProfilePage = () => {
  return (
    <Fragment>
      <MyProfile />
    </Fragment>
  );
};

export default MyProfilePage;
