import React, { Fragment, useState, useRef } from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import axios from "axios";

const ButtonDiv = ({ label, handler }) => {
  return (
    <div className='p-col-2'>
      <Button onClick={handler} label={label} />
    </div>
  );
};

const ShowFens = () => {
  const toast = useRef(null);
  const [show, setShow] = useState(false);
  const [adding, setAdding] = useState(false);
  const [updated, setUpdated] = useState("");
  const [editing, setEditing] = useState(null);
  const [selected, setSelected] = useState("");
  const [courses, setCourses] = useState({ left: [], right: [] });

  const handleLoadFen = async (e) => {
    let fen = e.target.innerText;

    try {
      const res = await axios.get(`/api/v1/fens/${fen}`, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
      setCourses({
        left: res.data.data.slice(0, 50),
        right: res.data.data.slice(50),
      });
      setSelected(fen);
    } catch (err) {}
  };

  const Header = () => {
    return (
      <Fragment>
        <div className='p-grid'>
          <ButtonDiv handler={handleLoadFen} label='Beginner' />
          <ButtonDiv handler={handleLoadFen} label='Misc01' />
          <ButtonDiv handler={handleLoadFen} label='Misc02' />
          <ButtonDiv handler={handleLoadFen} label='Misc03' />
          <ButtonDiv handler={handleLoadFen} label='Misc04' />
          <ButtonDiv handler={handleLoadFen} label='Misc05' />
          <ButtonDiv handler={handleLoadFen} label='Queens' />
          <ButtonDiv handler={handleLoadFen} label='Bishops' />
          <ButtonDiv handler={handleLoadFen} label='Rooks' />
          <ButtonDiv handler={handleLoadFen} label='Knights' />
          <ButtonDiv handler={handleLoadFen} label='Pawns' />
          <ButtonDiv handler={handleLoadFen} label='Intermediate' />
        </div>
        <h1 className='p-text-center'>{selected}</h1>
      </Fragment>
    );
  };

  const handleEdit = (rowData) => {
    setEditing(rowData);
    setShow(true);
  };
  const handleChangeFen = async () => {
    setAdding(true);
    try {
      const id = editing._id;
      const lesson = {
        id: editing.lesson.id,
        fen: updated,
      };
      const res = await axios.patch(`/api/v1/fens/${id}`, lesson, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });

      toast.current.show({
        severity: "success",
        summary: "The Fen was changed",
        detail: `${editing.courseName} with ID=${editing.lesson.id} was changed`,
        life: 5000,
      });
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: "The Fen was NOT changed",
        detail: `${editing.courseName} with ID=${editing.lesson.id} was NOT changed`,
        life: 5000,
      });
    }
    setAdding(false);
    setShow(false);
    setCourses({ left: [], right: [] });
    setSelected("");
    setUpdated("");
    setEditing(null);
  };

  const handleInputChange = (e) => {
    setUpdated(e.target.value);
  };

  const editButton = (rowData) => {
    return (
      <Button
        iconPos='left'
        icon='pi pi-pencil'
        className='p-button-raised p-button-warning p-mr-2 p-button-sm'
        onClick={() => handleEdit(rowData)}
      />
    );
  };

  return (
    <Fragment>
      <Toast ref={toast} />
      <div className='p-shadow-12'>
        <Card className='small-table'>
          <Header />
          <div className='p-grid'>
            {Object.keys(courses).map((side) => (
              <DataTable
                key={side}
                value={courses[side]}
                dataKey='_id'
                className='p-y-025rem p-col-6 '
                // header={header}
              >
                <Column
                  header='ID'
                  field='lesson.id'
                  style={{ width: "10%" }}
                />
                <Column
                  header='FEN'
                  field='lesson.fen'
                  style={{ width: "80%" }}
                />
                <Column body={editButton} style={{ width: "10%" }} />
              </DataTable>
            ))}
          </div>
        </Card>
        <Dialog
          header={
            <div className='p-text-center p-p-3 bg-blue white-text'>
              Editing FEN
            </div>
          }
          visible={show}
          style={{ width: "500px" }}
          onHide={() => {
            setShow(false);
          }}
          baseZIndex={1000}
        >
          {editing !== null && (
            <Fragment>
              <h5 className='p-text-center'>{editing.courseName}</h5>
              <h5 className='p-text-center'>ID is {editing.lesson.id}</h5>
              <h5 className='p-text-center'>{editing.lesson.fen}</h5>
              <div className='p-grid p-fluid'>
                <div className='p-col-12 p-field p-my-5'>
                  <label htmlFor='updated'>
                    <h5 className='p-text-center'>New FEN</h5>
                  </label>
                  <InputText
                    id='updated'
                    name='updated'
                    value={updated}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className='p-d-flex p-jc-end'>
                <Button
                  onClick={() => {
                    setShow(false);
                  }}
                  label='Cancel'
                  className='p-button-danger p-mr-5'
                />
                <Button
                  onClick={handleChangeFen}
                  label='Save'
                  className='p-button-success p-px-4'
                  loadingIcon='pi pi-spin pi-sun'
                  loading={adding}
                />
              </div>
            </Fragment>
          )}
        </Dialog>
      </div>
    </Fragment>
  );
};

export default ShowFens;
