import { AdminActionTypes } from "./admin.types";

const INITIAL_STATE = {
  isNewUserAdded: 0,
};

const adminReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AdminActionTypes.ADD_NEW_USER_PASS:
      return {
        ...state,
        isNewUserAdded: 1,
      };
    case AdminActionTypes.ADD_NEW_USER_FAIL:
      return {
        ...state,
        isNewUserAdded: -1,
      };

    case AdminActionTypes.ADD_NEW_USER_READY:
      return {
        ...state,
        isNewUserAdded: 0,
      };
    default:
      return {
        ...state,
      };
  }
};

export default adminReducer;
