import React, { Fragment, useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { loadArticles } from "../../../redux/article/article.actions";
import AddArticle from "./add-article.component";

const ShowArticles = ({ article, loadArticles }) => {
  const toast = useRef(null);
  const [globalFilter, setGlobalFilter] = useState(null);

  useEffect(() => {
    getAllArticles();
    // eslint-disable-next-line
  }, []);

  const getAllArticles = async () => {
    await loadArticles();
  };

  // const actionButtons = (rowData) => {
  //   const handleEdit = async (e) => {
  //     //await editClaimant(rowData);
  //   };

  //   return (
  //     <Fragment>
  //       <Button
  //         iconPos='left'
  //         icon='pi pi-pencil'
  //         label='Edit '
  //         className='p-button-raised p-button-warning p-mr-2 p-button-sm'
  //         onClick={handleEdit}
  //       />
  //     </Fragment>
  //   );
  // };

  const header = (
    <div className='p-d-flex p-jc-between'>
      <p className='m-0 fontl'>Articles</p>
      <AddArticle />
      <span className='p-input-icon-left'>
        <i className='pi pi-search' />
        <InputText
          type='search'
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder='Search...'
        />
      </span>
    </div>
  );

  return (
    <Fragment>
      <Toast ref={toast} />
      <div className='p-shadow-12'>
        <Card className='small-table'>
          <DataTable
            value={article.articles}
            dataKey='_id'
            className='p-y-025rem'
            paginator={article.articles.length > 10}
            paginatorTemplate='CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'
            currentPageReportTemplate='Showing {first} to {last} of {totalRecords}'
            rows={10}
            rowsPerPageOptions={[10, 20, 50]}
            globalFilter={globalFilter}
            header={header}
          >
            <Column
              field='numberDisplay'
              header='Number'
              sortable={true}
              style={{ width: "20%" }}
            />
            <Column
              field='articleName'
              header='Name'
              sortable={true}
              style={{ width: "45%" }}
            />
            <Column
              body={(rowData) => <AddArticle update={rowData} />}
              style={{ width: "35%" }}
            />
          </DataTable>
        </Card>
      </div>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  article: state.article,
});
export default connect(mapStateToProps, { loadArticles })(ShowArticles);
