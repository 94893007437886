import React, { Fragment } from "react";
import { Card } from "primereact/card";

const MyContactPage = () => {
  return (
    <div className='p-grid container'>
      <div className='p-offset-3 p-col-6 p-shadow-12 p-d-block p-mx-auto'>
        <Card title='Chess Thinker Question'>
          <h4 className='p-text-center'>Please send your question to</h4>
          <h5 className='p-text-center'>questions@grayknight.net</h5>
        </Card>
      </div>
    </div>
  );
};

export default MyContactPage;
