import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Card } from "primereact/card";
import { ProgressSpinner } from "primereact/progressspinner";
import { Image } from "primereact/image";
import Login from "../../components/login/login.component";
import MEMBER from "../../assets/images/members.jpg";

import { registerUserComplete } from "../../redux/user/user.actions";

const LoginPage = ({
  user,
  registerUserComplete,
  match: {
    params: { token },
  },
}) => {
  const [tokenStatus, setTokenStatus] = useState(0);

  useEffect(() => {
    if (token === undefined) return setTokenStatus(0);
    if (token === "changed") return setTokenStatus(1);
    if (token === "notchanged") return setTokenStatus(2);
    setTokenStatus(3);
    confirmToken();
    // eslint-disable-next-line
  }, []);

  const confirmToken = async () => {
    await registerUserComplete(token);
    setTokenStatus(0);
  };

  

  return (
    <Fragment>
      <div className='p-d-md-none'>
        <div className='p-grid  p-mx-4'>
          <div className='p-col-12'>
            <Image
              src={MEMBER}
              alt='Chess pieces'
              imageStyle={{ width: "100%" }}
            />
            <h1 className='p-text-center'>Time to work on sets!</h1>
            <h1 className='p-text-center'>Sign in</h1>

            {tokenStatus === 0 && <Login />}
            {tokenStatus === 1 && (
              <Fragment>
                <Login msg='Your password has been changed' />
              </Fragment>
            )}
            {tokenStatus === 2 && (
              <Fragment>
                <Login msg='Your password was not changed' />
              </Fragment>
            )}
            {tokenStatus === 3 && user.isRegistrationEmailConfirmed === -1 && (
              <Fragment>
                <div className='p-shadow-12 p-d-block p-mx-auto'>
                  <div className='p-fluid p-grid p-dir-col'>
                    <h5>Your email address could not be confirmed</h5>
                  </div>
                </div>
              </Fragment>
            )}
            {tokenStatus === 3 && user.isRegistrationEmailConfirmed === 0 && (
              <Fragment>
                <div className='p-d-block p-mx-auto'>
                  <Card title='Your email address is being confirmed'>
                    <div className='p-d-flex p-jc-center'>
                      <ProgressSpinner />
                    </div>
                  </Card>
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </div>
      <div className='p-d-none p-d-md-block'>
        <div className='p-grid container'>
          <div className='p-offset-3 p-col-6'>
            <Image
              src={MEMBER}
              alt='Chess pieces'
              imageStyle={{ width: "100%" }}
            />
            <h1 className='p-text-center'>Time to work on sets!</h1>
            <h1 className='p-text-center'>Sign in</h1>

            {tokenStatus === 0 && <Login />}
            {tokenStatus === 1 && (
              <Fragment>
                <Login msg='Your password has been changed' />
              </Fragment>
            )}
            {tokenStatus === 2 && (
              <Fragment>
                <Login msg='Your password was not changed' />
              </Fragment>
            )}
            {tokenStatus === 3 && user.isRegistrationEmailConfirmed === -1 && (
              <Fragment>
                <div className='p-shadow-12 p-d-block p-mx-auto'>
                  <div className='p-fluid p-grid p-dir-col'>
                    <h5>Your email address could not be confirmed</h5>
                  </div>
                </div>
              </Fragment>
            )}
            {tokenStatus === 3 && user.isRegistrationEmailConfirmed === 0 && (
              <Fragment>
                <div className='p-d-block p-mx-auto'>
                  <Card title='Your email address is being confirmed'>
                    <div className='p-d-flex p-jc-center'>
                      <ProgressSpinner />
                    </div>
                  </Card>
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, { registerUserComplete })(LoginPage);
