import React, { Fragment } from "react";
import { Image } from "primereact/image";
import DETAILS from '../../assets/images/details.jpg'


const InnerImage = () => (
  <div className='p-grid'>
    <div className='p-col-9'>
      <h4 className='p-mt-6'>
        Most top chess coaches charge $20 to $60 . Not everyone can afford to
        hire a chess coach. And finding a coach that actually helps you or has a
        spot on his schedule is another barrier that is sometimes hard to
        overcome.
      </h4>
    </div>
    <div className='p-col-3'>
      <Image src={DETAILS} alt='Chess pieces' imageStyle={{ width: "100%" }} />
    </div>
  </div>
);

const InnerPage = ({ show }) => (
  <Fragment>
    <h1 className='p-text-center'>Details</h1>
    {show === true ? (
      <InnerImage />
    ) : (
      <h4>
        Most top chess coaches charge $20 to $60. Not everyone can afford to
        hire a chess coach. And finding a coach that actually helps you or has a
        spot on his schedule is another barrier that is sometimes hard to
        overcome.
      </h4>
    )}

    <h4>
      For 40+ years I have taught chess in person and online. For the past few
      years I have been one of the top chess teachers/coaches on the ICC under
      the name Curmudgeon. My waiting list is fairly long. I would love to teach
      all that come to me but there are only so many hours in the week. So I
      decided to create a website where it would be as close to someone having a
      chess coach as could be and at a price that everyone could afford.
    </h4>

    <h4>
      Not only will you be able to work on the same positions I have worked with
      my students over the years but you can also ask me chess questions! Ask me
      about a particular position that is giving you trouble. Ask about how to
      study openings. If it is chess related, ask away!
    </h4>

    <h4>
      ChessThinker.com is not only for beginning chess players learning how to
      mate, but more advanced players as well. For example: If you have trouble
      in knight endings, then come to ChessThinker.com and work on the Knight
      Ending set. Need work on Rook endings? Then there is a set for that.
      Whatever chess weakness you have, we have got you covered.
    </h4>

    <h4>
      We have 12 sets. Each set has 100 positions. At chess tactics sites you
      get a position and if you figure out the 1st move, you get credit. But
      what good is being a piece or pawn up if you can not win it against a
      strong player? You need to work on your technique while on the clock. The
      only place to do that is at ChessThinker.com
    </h4>

    <h4>
      Once you select the position you want to work on, you get 30 minutes on
      the clock while your opponent gets 10. You MUST checkmate your opponent or
      you do not get credit. It is that simple. This is by far the best way to
      improve your technique. By the way, your opponent is VERY good at getting
      stalemates! So be very careful!
    </h4>
    <h1 className='p-text-center'>Donny Gray</h1>
  </Fragment>
);
const DetailsPage = () => {
  return (
    <Fragment>
      <div className='p-grid container'>
        <div className='p-d-md-none p-col-12'>
          <div className='p-mx-5'>
            <InnerPage />
          </div>
        </div>
        <div className='p-d-none p-d-md-block p-col-8 p-offset-2'>
          <InnerPage show={true} />
        </div>
      </div>
    </Fragment>
  );
};

export default DetailsPage;
