import React, { Fragment } from "react";
import { Button } from "primereact/button";

const ArticleColumn = ({ column, articleHandler }) => {
  return (
    <Fragment>
      {column.map((art) => {
        return (
          <Fragment key={art._id}>
            <div className='p-d-flex'>
              <Button
                className='p-button-link'
                onClick={() => articleHandler(art)}
              >
                <p className='fontl-m p-my-1 p-mr-3'>{art.numberDisplay}</p>
                <p className='fontl-m p-my-1 p-mr-3'>{art.articleName}</p>
              </Button>
            </div>
          </Fragment>
        );
      })}
    </Fragment>
  );
};

export default ArticleColumn;
