import React, { Fragment } from "react";
import Contact from "../../components/contact/contact.component"

const ContactPage = () => {
  
  return (
    <Fragment>
      <Contact />
    </Fragment>
  );
};

export default ContactPage;
