import SET1 from "../../assets/images/set-1.png";
import SET2 from "../../assets/images/set-2.png";
import SET3 from "../../assets/images/set-3.png";
import SET4 from "../../assets/images/set-4.png";
import SET5 from "../../assets/images/set-5.png";
import SET6 from "../../assets/images/set-6.png";
import SET7 from "../../assets/images/set-7.png";
import SET8 from "../../assets/images/set-8.png";
import SET9 from "../../assets/images/set-9.png";
import SET10 from "../../assets/images/set-10.png";
import SET11 from "../../assets/images/set-11.png";
import SET12 from "../../assets/images/set-12.png";

export const images = [
  {
    id: 1,
    image: SET1,
    alt: "Clipboard with checklist called Pawns",
  },
  {
    id: 2,
    image: SET2,
    alt: "Clipboard with checklist called Bishops",
  },
  {
    id: 3,
    image: SET3,
    alt: "Clipboard with checklist called Knights",
  },
  {
    id: 4,
    image: SET4,
    alt: "Clipboard with checklist called Rooks",
  },
  {
    id: 5,
    image: SET5,
    alt: "Clipboard with checklist called Queens",
  },
  {
    id: 6,
    image: SET6,
    alt: "Clipboard with checklist called Misc01",
  },
  {
    id: 7,
    image: SET7,
    alt: "Clipboard with checklist called Misc02",
  },
  {
    id: 8,
    image: SET8,
    alt: "Clipboard with checklist called Misc03",
  },
  {
    id: 9,
    image: SET9,
    alt: "Clipboard with checklist called Misc04",
  },
  {
    id: 10,
    image: SET10,
    alt: "Clipboard with checklist called Misc05",
  },
  {
    id: 11,
    image: SET11,
    alt: "Clipboard with checklist called Beginner",
  },
  {
    id: 12,
    image: SET12,
    alt: "Clipboard with checklist called Intermediate",
  },
];
