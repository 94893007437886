import React, { Fragment } from "react";
import { RadioButton } from "primereact/radiobutton";

const FreePlan = ({ plans, selectedPlan, setSelectedPlan }) => {
  return (
    <Fragment>
      <div className='p-d-flex'>
        {plans.map((plan) => (
          <div key={plan.key} className='p-field-radiobutton p-mx-3'>
            <RadioButton
              inputId={plan.key}
              name='plan'
              value={plan}
              onChange={(e) => setSelectedPlan(e.value)}
              checked={selectedPlan.key === plan.key}
            />
            <label htmlFor={plan.key}>{plan.name}</label>
          </div>
        ))}
      </div>
    </Fragment>
  );
};

export default FreePlan;
