import React, { Fragment } from "react";
import { Image } from "primereact/image";
import HINTS from '../../assets/images/hints.jpg'

const InnerPage = () => (
  <Fragment>
    <Image
      src={HINTS}
      alt='King fell over on a chessboard board'
      imageStyle={{ width: "100%" }}
    />
    <h1 className='p-text-center'>How to get the most from ChessThinker</h1>

    <h4>
      Anyone that has gotten good in anything has practiced. A lot. Just because
      you have done something one time does not mean you are an expert at it.
    </h4>

    <h4>
      For example. Do you think a professional basketball player that has made a
      free throw, never practices free throws ever again? No! He practices them
      all the time. Same idea applies to chess.
    </h4>

    <h4>
      Just because you can do a king and queen checkmate one time does not mean
      you should never practice it again. You need to practice it many times so
      that if it ever comes up in a real game you are ready for it and can do it
      with little effort.
    </h4>

    <h4>
      Improving your technique in chess is the same but on a higher level than
      just a king and queen mate.
    </h4>

    <h4>
      Start out on ChessThinker by choosing a set to work on. Go thru the entire
      set and if it was very easy for you to do then move on to another set. But
      if you had to think a lot or start over a lot on some of the positions,
      then do the entire set over. If you can do the set a 2nd time faster than
      you did the 1st time, then you have improved your chess technique!
    </h4>

    <h4>
      Once you start working on these sets and start to knock them out faster
      and faster, you will start to see the same type positions in your real
      games. Once you get the feel of some of these type of endings you can
      start to play openings that produce those very endings!
    </h4>

    <h4>
      For example. Nothing to me is better than a good knight ending. So I look
      for variations in my openings that lead to knight endings. You can do the
      same thing no matter what type of endings interest you the most. Play the
      game and force it to what you are good at. Play the beginning of the game
      with an eye on the ending.
    </h4>
  </Fragment>
);

const HintsPage = () => {
  return (
    <Fragment>
      <div className='p-grid container'>
        <div className='p-d-md-none p-col-12'>
          <div className='p-mx-5'>
            <InnerPage />
          </div>
        </div>
        <div className='p-d-none p-d-md-block p-col-8 p-offset-2'>
          <InnerPage show={true} />
        </div>
      </div>
    </Fragment>
  );
};

export default HintsPage;
