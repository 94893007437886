import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
//import storage from "redux-persist/lib/storage";
import storageSession from "redux-persist/lib/storage/session";

import userReducer from "./user/user.reducer";
import adminReducer from "./admin/admin.reducer";
import articleReducer from "./article/article.reducer";

// const persistConfig = {
//   key: "root",
//   //storage,
//   storage: storageSession,
//   whitelist: ["user"],
// };

const rootReducer = combineReducers({
  user: userReducer,
  admin: adminReducer,
  article: articleReducer,
});

// export default persistReducer(persistConfig, rootReducer);
export default rootReducer;
