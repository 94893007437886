import React from "react";
import AcceptInvite from "../../components/accept-invite/accept-invite.component";

const AcceptInvitePage = ({
  match: {
    params: { tok },
  },
}) => {
  return (
    <div className='bg-image'>
      <AcceptInvite token={tok} />
    </div>
  );
};

export default AcceptInvitePage;
