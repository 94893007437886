import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
//import { confirmUser } from "../../redux/user/user.actions";
import { loadReturnUser } from "../../redux/user/user.actions";

const ReturnPage = ({
  history,
  // user,
  loadReturnUser,
  match: {
    params: { page },
  },
}) => {
  const [check, setCheck] = useState(false);

  useEffect(() => {
    confirmUser();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (check === true) checkUser();
    // eslint-disable-next-line
  }, [check]);

  const confirmUser = async () => {
    try {
      await loadReturnUser();
    } catch (err) {}
    setCheck(true);
  };

  const checkUser = async () => {
    if (page === "articles") history.push("/my/articles");
    else if (page === "contact") history.push("/my/contact");
    else if (page === "profile") history.push("/my/profile");
    else if (page === "add-time-pass") history.push("/add-time-pass");
    else if (page === "add-time-fail") history.push("/add-time-fail");
    else history.push("/login");
  };

  return <Fragment></Fragment>;
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default withRouter(
  connect(mapStateToProps, { loadReturnUser })(ReturnPage)
);
